$serif-font: athelas, serif;
$sans-font:  adelle-sans, sans-serif;
$body-font: $serif-font;
$heading-font: $sans-font;
$serif-heading-font: $serif-font;
$sidenotes-font: $serif-font;
$marginnotes-font: $sans-font;
$japanese-font: TakaoPGothic, "Noto Sans JP", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
$mono-font: lft-etica-mono,Inconsolata,Consolas,Courier, monospace;
$vita-font: "athelas", serif;

// Font Sizes 
$base-size: 16px !default;
$small-size:  .9rem !default;
$smaller-size:  .8rem !default;
$smallest-size:  .7rem !default;
$tiny-size: .6rem !default;
$micro-size: .5rem !default;
$nano-size: .4rem !default;
$large-size:  1.3rem !default;
$larger-size:  1.5rem !default;
$largest-size:  1.7rem !default;
$huge-size:  2.2rem !default;
$title-size: 3.6rem !default;
$subtitle-size: 2.8rem !default;

$body-size: 1rem !default;
$h1: 3.6rem !default;
$h2: 2.8rem !default;
$h3: 2.2rem !default;
$h4: 1.7rem !default;
$h5: 1.3rem !default;
$h6: 1.1rem !default;
$icons-large: 3rem;
$icons-medium: 2rem;
$icons-small: 1.5rem;
$contacticon-large: 3rem;
$contacticon-medium: 2rem;
$contacticon-small: 1.5rem;

// font weights
$black-weight: 			900 !default;
$heavy-weight: 			800 !default;
$bold-weight: 			700 !default;
$medium-weight: 		600 !default;
$book-weight: 			500 !default;
$normal-weight: 		400 !default;
$light-weight: 			300 !default;
$extra-light-weight: 	200 !default;
$thin-weight: 			100 !default;

// line heights
$line-height: 1.5 !default;
$short-line-height:  1.3 !default;
$shorter-line-height:  1.2 !default;
$shortest-line-height:  1.1 !default;
$tall-line-height:  1.6 !default;
$taller-line-height:  1.7 !default;
$tallest-line-height:  1.8 !default;
$single-line-height: 1 !default;
$double-line-height: 2 !default;
$doublehalf-line-height: 2.5 !default;
$header-height: 56px;
$footer-height: 40px;

// COLORS

// reds & pinks 
$red:               #FF3636 !default;
$dull-red:          #b03a2e !default;
$pastel-pink1: 		#F0DDF0 !default;
$pastel-pink2: 		#FEF1EB !default;

// oranges
$orange:            #F29105 !default;
$deep-orange:       #973A05 !default;
$dull-orange: 		#B16032 !default;

// yellows 
$yellow:            #efcc00 !default;
$pastel-yellow: 	#FBE7C5 !default;
$cornsilk: 			#FFF8DC !default;
$ivory: 			#FFFFF0 !default;

// greens
$green:             #00ab37 !default;
$dark-green:        #006400 !default;
$light-green:       #ddffdd !default;
$nyanza: 			#ECFFDC !default;
$pastel-green: 		#E8F5DF !default;

// blues
$blue:              #2698BA !default;
$dark-blue:         #00369f !default;
$bright-blue:       #3498db !default;
$bright-cyan:       #2EA4B0 !default;
$bright-navy:       #2E55B0 !default;
$pastel-blue1: 		#9FD2F7 !default;
$pastel-blue2: 		#AAE8FC !default;
$azure: 			#F0FFFF !default;
$aliceblue: 		#F0F8FF !default;

// whites
$white:             #ffffff !default;
$off-white: 		#fffff8 !default;

// greys
$grey: 				#828282 !default;
$dark-grey:         #17202a !default;
$medium-grey:       #2c3e50 !default;
$light-grey:        #d5d8dc !default;
$lighter-grey:      #eeeeee !default;
$grey-green:        #424949 !default;
$platinum:			#EBE6E5 !default;
$gainsboro: 		#DFD8DC !default;
$pastel-grey1: 		#D4CFBD !default;
$pastel-grey2: 		#EDEAF2 !default;

// blacks 
$black: 			#000000 !default;
$off-black: 		#191919 !default;

// Theme Colors 
$theme-color:       $dull-red;
$background-color:  $off-white;
$text-color:        $off_black;
$contrast-color:    $dull-red;
$heading-color: 	$dull-red;
$code-color:        $bright-cyan;
$link-color:		$deep-orange;
$link-hover-color:  $dull-orange;
$text-box-color:	$ivory;
$code-box-color:	$aliceblue;

// Button Colors (Bijou) 
$primary:			$theme-color;
$success: 			#4DAF7C;
$danger: 			#E6623C;

// Borders
$border-color: $light-grey !default;
$border-width: 1px !default;
$border-radius: 3px !default;

// Viewport widths
$viewport-small: 600px;
$viewport-medium: 768px;
$viewport-large: 1000px;

// Breakpoints
$breakpoint-md: '(min-width: 52em)';
$breakpoint-xl: '(min-width: 96em)';

// Portfolio 
$img_spacing: 5px;
$img-height: 275px;
$caption-font-size: $smaller-size;
$caption-color: $off-black;

// Buttons
$button-font-size: inherit !default;
$button-font-weight: normal !default;
$button-line-height: 1.125rem !default;
$button-padding-y: .5rem !default;
$button-padding-x: 1rem !default;
$button-font-family: $sans-font;

// Forms
$form-field-font-size: 1rem !default;
$form-field-height: 2.25rem !default;
$form-field-padding-y: .5rem !default;
$form-field-padding-x: .5rem !default;

// max-width for header .site-nav hamburger menu
$on-palm:  799px !default;
$device:  799px !default;