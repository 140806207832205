@use "sass:color";

.syllabus {
    padding-top: .5rem;
	max-width: 40em;
}

.course-info {
	margin-bottom: 2rem;
}

.course-info p {
    font-size: $smaller-size;
	font-family: $sans-font;
    font-weight: $normal-weight;
    font-style: normal;
    margin: .2rem 0 0rem 0;
}

.syllabus h1 {
	font-family: $sans-font;
	color: $theme-color;
	margin-top: 1.5rem;
    margin-bottom: 0rem;
}
.syllabus h2 {
	font-family: $sans-font;
	color: $theme-color;
    font-style: normal;
    font-weight:$normal-weight;
	line-height: 1;
	margin: 4rem 0 0 0;
}
.syllabus h3 {
    font-size: $largest-size;
	font-weight: $normal-weight;
	font-style: normal;
	margin: 2rem 0 .5rem 0;
}
.syllabus h4 {
    font-size: $large-size;
	font-weight: $normal-weight;
	font-style: italic;
	margin: 1rem 0 .5rem 0;
}
.syllabus h5 {
    font-size: $base-size;
	margin: 1rem 0 .5rem 0;
}
.syllabus h6 {
    font-size: $smaller-size;
	font-family: $sans-font;
    font-weight: $normal-weight;
    font-style: normal;
    margin: .2rem 0 0rem 0;
}

.syllabus a {
    color: $theme-color;
    text-decoration: none;
    &:hover {
		color:color.adjust($theme-color, $lightness: -50%);
	    text-decoration: underline;
    }
}

.syllabus table {
	margin: 2rem 0 1rem 0;
	max-width: 100%;
}
.syllabus th {
	font-size: $body-size;
	font-weight: $bold-weight;
}
.syllabus td {
	font-size: $small-size;
}