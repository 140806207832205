pre {
	overflow: auto; 
}
pre, pre code, p code, p pre code {
	font-family: $mono-font;
	font-size: $smaller-size;
	line-height: $taller-line-height;
}

/* Unset the tufte-css defaults that we'd like to overwrite */
pre > code {
	margin-left: initial;
	overflow: initial;
	display: initial;
}
  
.sourceCode.numberLines a:link {
	text-decoration: initial;
	background: initial;
	text-shadow: initial;
}
  
@media (max-width: 760px) {
	div.sourceCode,
	pre:not(.sourceCode) {
	  padding: 1.4rem 8vw;
	  margin: -0.7rem -8vw;
	  width: 100%;
	}
	.fullwidth {
	  max-width: 100%;
	}
}