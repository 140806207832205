/* Figures & Images */
figure, figure img.maincolumn { 
	max-width: 100%;
    margin-bottom: 3em; 
	}

figcaption { 
    font-size: $small-size;
    text-align: center;
    line-height: $line-height;
    margin: 1rem auto 0 auto;
    position: relative;
    max-width: 75%; 
}

figure.fullwidth figcaption { 
	margin-left: auto;
	margin-right: auto; 
	max-width: 90%; 
}

img { 
	max-width: 100%; 
}

.image-container {
	position: relative;
	display: inline-block;
	padding: 10px 0;
	width: 75%
}
	
.image-container img {
	display: block;
	width: 100%;
	height: auto;
}

.overlay-text {
	position: absolute;
	top: 50%; 
	left: 50%; 
	transform: translate(-50%, -50%); 
	font-family: $sans-font;
	font-size: 2.5rem; 
	font-weight: $medium-weight;
	text-align: center;
	color: $off-black;
	text-shadow: 1px 1px 2px black;
        a {
            color: $off-white;
            &:hover {
                color: $dull-red;
            }
        }
}

@media screen and (max-width: 800px) {
    .overlay-text {
		font-size: 2rem
    }
}