.profile-image {
	background-color: #cccccc;
	height: 100%;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	opacity: 0.8;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
}
.profile-block {
	width: 100%;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
}
.profile-block-left {
	width: 100%;
    text-align: left;
	position: absolute;
    top: 0;
    left: 0;
	margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 0;
}
.profile h1 {
    color: $off-white;
    margin: 0 0 1rem 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}
.profile h2 {
    font-size: 1.5rem;
	font-weight: $normal-weight;
    color: $off-white;
    margin: 0 0 2rem 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}
.profile h3 {
    font-size: 1.2rem;
	font-weight: $normal-weight;
    color: $off-white;
    margin: 0 0 2rem 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}
.profile a {
    color: $off-white;
    text-decoration: none;
    &:hover {
		color:$link-hover-color;
    }
}
.profile1 {
    font-size: $title-size;
    line-height: $line-height;
    font-weight: $bold-weight !important;
    color: white;
	width: 100%;
    margin: 0 auto 0 auto;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}
.profile1a {
    font-size: $subtitle-size;
    line-height: $line-height;
    font-weight: $bold-weight !important;
    color: white;
	width: 100%;
    margin: 0 auto 0 auto;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}
.profile2 {
    font-size: $h2;
    font-style: normal;
    line-height: $line-height;
    font-weight: $bold-weight !important;
    color: white;
	width: 100%;
    margin: 1rem auto 0 auto;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}
.profile3 {
    font-size: $h3;
    line-height: $line-height;
    font-weight: $bold-weight !important;
    color: white;
	width: 100%;
    margin: 1rem auto 0 auto;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}
.profile4 {
    font-size: $h4;
    line-height: $line-height;
    font-weight: $bold-weight !important;
	font-style: normal;
    color: white;
	width: 100%;
    margin: 1rem auto 0 auto;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}
.profile4 p {
    line-height: $line-height;
    font-weight: $bold-weight !important;
	font-style: normal;
    color: white;
	width: 100%;
    margin: 0 auto 0 auto;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}

.social {
    border-top: 0px solid $light-grey;
    margin-top: 2rem;
    line-height: 2.5;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.list-inline {
    padding: 0;
    list-style: none;
	max-width: 80%;
	margin-left: auto;
	margin-right: auto;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
}

.list-inline>li {
    font-size: $icons-medium;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

.list-inline-item {
    display: inline-block;
}

.social-left {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    line-height: 2.5;
}

.list-inline-left {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.list-inline-left>li {
    font-size: $icons-small;
    display: inline-block;
    margin: 0 0 0 0;
    padding: 0 25px 0 0;
}

.social-icons-left, .social-icons-right {
  text-align: center;
  float: none;
}

.social-icons {
  font-size: $icons-medium;
  padding: 0.5em 0 0 0;
  width: 100%;
}

.social-icons a.fa {
  cursor: pointer;
  opacity: 0.8;
  padding: 0.2em;
}

.social-icons a.fa:hover {
  opacity: 1;
}

.social-icons iframe[title=Flattr] {
  position: relative;
  top: 0.1em;
}

.contacticon {
    display: block;
    font-size: $icons-large;
    margin: 10px;
}