@use "sass:color";

footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
}
.home-footer {
	font-family: $sans-font;
	background-color: $dark-grey;
	border-top: thin solid $dark-grey;
	color: $light-grey;
	font-size: $tiny-size;
	padding: 0;
	position: fixed;
	left: 0px;
	bottom: 0px;
	width: 100%;
}
.home-footer p {
	font-size: $tiny-size;
	font-weight: $normal-weight;
	margin-top: 0;
	margin-bottom: 0;
}
.home-footer a { 
	color: $white !important;
	&:hover {
	color:color.adjust($white, $lightness: -50%) !important;
	}
}
.home-footer-block {
    position: relative;
	max-width: 20rem;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0;
	margin-bottom: 0;
	padding: 1% 0;
	height: 100%;
}
.home-footer-block p {
	font-size: $tiny-size;
    position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0;
	margin-bottom: 0;
}