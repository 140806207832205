.preface {
    padding: 75px 0; 
	width: 50vw;
}

.casebook {
    padding: 75px 0; 
	width: 50vw;
}

/* HEADINGS */

/* Preface */
.preface h1 {
	color: $dull-red;
	font-family: $sans-font, sans-serif;
	font-weight: $normal-weight;
}
/* Chapter */
.chapter {
	counter-reset: sidenote-counter;
}
.casebook h1 {
	color: $dull-red;
	font-family: $sans-font, sans-serif;
	font-weight: $normal-weight;
	counter-increment: chapter;  /* Add 1 to chapter */
    counter-reset: section;      /* Set section to 0 */
}
/* 
.casebook h1:before {
	content: "Chapter " counter(chapter) ": ";
}
*/
/* Section */
.casebook h2 {
	color: $dull-red;
	font-weight: $normal-weight;
	font-style: normal;
	margin: 5rem 0 0 0;
    counter-increment: section;  /* Add 1 to section */
    counter-reset: subsection;      /* Set subsection to 0 */
}
.casebook h2:before {
	content: counter(section) ". ";
}
/* Subsection */
.casebook h3 {
	color: $dull-red;
	font-weight: $normal-weight;
	font-style: normal;
	margin: 3rem 0 0 0;
    counter-increment: subsection;	/* Add 1 to section */
    counter-reset: subsubsection;
}
.casebook h3:before {
    content: counter(section) '.' counter(subsection) " ";
}
/* Title and cite of incorporated materials */
.casebook h4 {
	color: $dull-red;
	font-weight: $normal-weight;
	margin: 2rem 0 0rem 0;
	border-bottom: 1px solid $dull-red;
}

/* Section headings within incorporated materials */
.casebook h5 {
    margin: 2.5rem 0 0 0;
}
.casebook h6 {
    margin: 1.5rem 0 0 0;
}

p.case-h1 {
	font-size: $large-size;
    font-style: normal;
    font-weight: $medium-weight;
    margin: 2.5rem 0 0 0;
	list-style: outside none none !important;
}
p.case-h2 {
	font-size: $large-size;
    font-style: italic;
    font-weight: $normal-weight;
    margin: 1.5rem 0 0 0;
	list-style: outside none none !important;
}
p.case-h3 {
	font-size: $body-size;
    font-style: normal;
    font-weight: $medium-weight;
    margin: 1.5rem 0 0 0;
	list-style: outside none none !important;
}
p.case-h4 {
	font-size: $body-size;
    font-style: italic;
    font-weight: $normal-weight;
    margin: 1.5rem 0 0 0;
	list-style: outside none none !important;
}

/* Blockquotes */
.casebook blockquote p {
	font-style: normal;
}

/* List Items */
.casebook ol {
	font-size: inherit;
}
.casebook ul {
	font-size: inherit;
	list-style-type: disc;
}
.casebook li {
	font-size: inherit;
}

/* Divider */
.casebook hr {
	border-top: 2px solid $theme-color;
	width:33%;
	margin:3rem auto 3rem auto;
}

/* EXPLANATIONS & COMMENTS */
.comment {
	font-family: $sans-font;
	margin: 5rem 0;
	padding-bottom: 1rem;
	border-top: 2px solid $theme-color;
	border-bottom: 2px solid $theme-color;
}
.comment p {
	font-family: $sans-font;
}
.comment blockquote {
	margin: 1rem 0 1rem 2.5%;
	padding: 0 5% 0 2.5%;
	border-left: 3px solid $theme-color;
}
.comment blockquote blockquote {
	border: none;
}
.comment blockquote p {
	font-style: italic;
}
.comment blockquote blockquote p {
	font-size: $smaller-size;
	font-style: normal;
}
.comment h4 {
	font-family: $sans-font;
	border: none;
}
.comment h5 {
	font-family: $sans-font;
}
.comment h6 {
	font-family: $sans-font;
}
.comment ul { 
	list-style-type: disc;
}
/* LEGAL CODE: Indented subsections in statutes, etc. */

/* Title and cite of incorporated materials */
.legal-code h4 {
	color: $dull-red;
	font-weight: $normal-weight;
	margin: 2rem 0 0rem 0;
	border-bottom: 1px solid $dull-red;
}
.legal-code h5 {
	font-style: normal;
}
/* Heading for Official Comments */
.legal-code h6 {
	font-style: normal;
}
/* Use for indented sections in statutes, etc. */
.legal-code ul { 
	list-style-type: none;
	font-size: $body-size;
	font-weight: $normal-weight;
	margin: .5rem 0 .5rem 2rem; 
	padding: 0 0 0 0;
}

.legal-code li { 
	font-size: $body-size;
	line-height: $line-height;
	margin-bottom: 0.5rem;
}
.legal-code blockquote {
	font-size: $body-size;
	padding: 0 0 0 1.5rem;
}
.legal-code blockquote p {
	font-size: $body-size;
}
/* FIGURES & IMAGES */
.casebook figure {
    padding: 0;
    border: 0;
    margin: 2em 0 2em 0;
}
.marginnote figure {
    padding: 0;
    border: 0;
    margin: 1em 0 1em 0;
}
.casebook figcaption {
	float: none;
	font-family: $sans-font;
    font-size: $small-size;
    text-align: center;
}
.marginnote figcaption, .sidenote figcaption { 
    font-size: $smallest-size;
	}
.casebook figure > img {
	  display: block;
  } 
.casebook figure.fullwidth figcaption { 
	float: none;
	margin: 1rem 0 2rem 0; 
	}
.casebook img {
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	}
/* PAGE-NAV TOC */
#table-of-contents {
    float: left;
    background: #f9f9f9 none repeat scroll 0 0;
    border: 1px solid #aaa;
    display: table;
    margin: 0 0 0 0;
    padding: 10px 10px 10px 0;
    width: auto;
}
#toc-header {
	font-family: $sans-font;
    font-weight: $medium-weight;
    font-size: $smallest-size;
}
#table-of-contents li, #table-of-contents ul, #table-of-contents ul li {
	font-family: $sans-font;
	font-size: $tiny-size;
    list-style: outside none none !important;
	width: auto;
	padding: 0 0 0 0;
    margin-top: 0;
    margin-bottom: 0;
    line-height: $shorter-line-height;
}
#table-of-contents ul {
	max-height: 90vh;
	overflow-y: scroll;
}
li.toc-li-H1 {
    margin: 0 0 0 0;
    font-weight: $medium-weight;
}
li.toc-li-H2 {
    margin: 0 0 0 1em;
    font-style: italic;
}
li.toc-li-H3 {
    margin: 0 0 0 2em;
}
li.toc-li-H4 {
    margin: 0 0 0 3em;
}
:target {
	scroll-margin-top: 80px;
}

/* CASEBOOK COVER PAGE */ 
.book-cover {
	padding: 0 0 0 0;
}
.book-author {
    color: $theme-color;
    font-family: $heading-font;
    font-size: $larger-size;
	text-align: left;
	text-transform: uppercase;
    font-style: normal;
}
.title-block{
	margin: 2rem 0 0 0;
}
.book-title {
    color: $theme-color;
    font-family: $heading-font;
    font-size: $title-size;
	text-align: left;
	text-transform: uppercase;
    font-style: normal;
}
.book-subtitle {
    color: $theme-color;
    font-family: $heading-font;
    font-size: $larger-size;
	text-align: left;
	text-transform: uppercase;
    font-style: normal;
}
.publication-block{
	margin: 2rem 0 0 0;
}
.book-affiliation {
    color: $theme-color;
    font-family: $heading-font;
    font-size: $large-size;
	text-align: left;
	text-transform: uppercase;
    font-style: normal;
}
.book-date {
    color: $theme-color;
    font-family: $heading-font;
    font-size: $large-size;
	text-align: left;
	text-transform: uppercase;
    font-style: normal;
}
.colophon {
    font-family: $body-font;
	font-size: $smaller-size;
	text-align: left;
}
.colophon p {
	font-size: $smaller-size;
	text-align: left;
	line-height: $short-line-height;
}
.colophon li {
	font-size: $smaller-size;
	text-align: left;
}
.epigraph { 
    font-family: $body-font;
	font-size: $smaller-size;
	text-align: left;
	}
.epigraph-quote { 
	font-style: italic; 
	text-align: left;
	line-height: $taller-line-height;
  	}
.epigraph-cite {
	text-align: right;
	margin: 2rem 0 0 0;
	}
.epigraph-author {  
	font-style: normal;
	}
.epigraph-source { 
	font-style: italic; 
	}
