.page-container {
    padding: 75px 0; /* VERTICAL PADDING FOR TITLE ON EVERY PAGE */
	width: 66%;
}
.page-container-wide {
    padding: 75px 0; /* VERTICAL PADDING FOR TITLE ON EVERY PAGE */
}
.center-screen {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
.page-header {
	margin-bottom: 1rem;
}
.page-title {
    color: $theme-color;
    font-family: $heading-font;
    font-size: $title-size;
    line-height: $line-height;
    font-weight: $medium-weight;
    font-style: normal;
	margin-top: 0;
	margin-bottom: 0;
}
.page-subtitle {
    color: $theme-color;
    font-family: $heading-font;
    font-size: $largest-size;
    line-height: $line-height;
    font-weight: $normal-weight;
    font-style: normal;
    line-height: 1;
	margin-top: 1rem;
	margin-bottom: 0;
}

.page-date { 
	font-style: italic;
    margin: 2rem 0 2rem 0;
    font-size: 1.8rem;
    display: block;
    line-height: $single-line-height; 
	}

.page-cite {
    font-family: $serif-font;
	color: $text-color;
    font-size: $body-size;
    line-height: $line-height;
    font-weight: $normal-weight;
	margin-top: 1rem;
	margin-bottom: 0;
}

/* Sectioning */
article { 
	position: relative;
    padding: 1rem 0 1rem 0; 
	} 
.full-width article p{
	width: 90%;
	}
section { 
	padding: 0 0 0 0;
	}

.illustration {
    padding-top: 2rem;
    padding-top: 2rem;
    height: auto;
    width: 100%;
}
.center{
    text-align: center;
}


/* 404 PAGE */
.gevalt {
	padding: 0;
	margin: 0;
	max-width: 100%;
}
.gevalt-header {
	margin-bottom: .5rem;
	margin-left: 12.5%
}
.gevalt p {
    text-align: center;
	max-width: 90%;
	margin-left: 0;
	margin-right: 0;
}
.gevalt img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	height: 500px;
}
.gevalt h1 {
	color: $theme-color;
	font-weight: $medium-weight;
}
.gevalt h2 {
	height: 100%;
	color: $theme-color;
	text-align: center;
	vertical-align: middle;
	font-family: serif-font;
	font-size: $h5;
	font-weight: $normal-weight;
	font-style: italic;
	margin-top: 1rem;
	margin-left: auto;
	margin-right: auto;
	padding-left: 35%;
	padding-right: 35%
}