@media screen and (max-width: 799px) { 
	html{ 
		font-size: 12px;
	} 
	#table-of-contents li, #table-of-contents ul, #table-of-contents ul li {
		font-size: $smallest-size;
	}
	pre code { 
		width: 87.5%; 
	}
	ul { 
		width: inherit; 
	}
	figcaption, figure.fullwidth figcaption {
		margin-right:0%;
		max-width: none; 
	}
	blockquote p, blockquote footer, div.epigraph > blockquote > footer,.MJXc-display {  
		width: inherit;
		padding: 0 0 0 0;
	}
 /* Hide sidenotes */
	label.margin-toggle:not(.sidenote-number) { 
		display: inline; color: $contrast-color; 
	}
	.sidenote, .marginnote { display: none; }
	.margin-toggle:checked + .sidenote, .margin-toggle:checked + .marginnote {
		display: block;
		float: left;
		left: 1rem;
		clear: both;
		width: 95%;
		margin: 1rem 2.5%;
		position: relative; 
	}
	label { 
		cursor: pointer; 
	}
	pre, pre code, p code, p pre code { 
		width: 90%;
		padding: 0; 
	}
	table-caption { 
		display: block;		
		float: right;
		clear: both;
		width: 98%;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		margin-left: 1%;
		margin-right: 1%;
		position: relative; 
	}
	div.table-wrapper, table, table.booktabs { 
		width: 85%; 
	}
	div.table-wrapper { 
		border-right: 1px solid #efefef; 
	} 
	figcaption.maincolumn-figure, figure.fullwidth figcaption { 
		float:none; text-align: center;
	}
	figure.fullwidth figcaption { 
		margin-left: 0; 
	}
/* 404 page */
	.gevalt img {
		height: 350px;
		}
	.gevalt h2 {
		padding-left: 15%;
		padding-right: 15%
		}
}
@media screen and (min-width: 800px) {
    .wrapper {
        max-width: -webkit-calc(900px - (#{50px} * 2));
        max-width:         calc(900px - (#{50px} * 2));
        margin-right: auto;
        margin-left: auto;
        padding-right: 50px;
        padding-left: 50px;
    }
}
@media screen and (min-width: 800px){ 
	html{ font-size: 14px;} 
	}
@media screen and (min-width: 1000px){ 
	html{ font-size: 16px;} 
	}
@media screen and (min-width: 1200px){ 
	html{ font-size: 18px; } 
	}
