.vita-container {
    padding: 75px 0; /* VERTICAL PADDING FOR TITLE ON EVERY PAGE */
	width: 60vw;
	margin-left: auto;
	margin-right: auto;
}
.vita {
    font-family: $vita-font;
	font-weight: $normal-weight;
    padding-bottom: 20px;
}
.vita-button {
	float:right;
	padding-bottom: 2px;
}
.vita p {
    font-family: $vita-font;
}
.vita li {
    font-family: $vita-font;
}
.vita ul, .vita ol {
    font-family: $vita-font;
	font-size: $body-size;
}
.vita-header {
	margin-top: 0;
	margin-bottom: 0;
}
.vita-contact {
    font-family: $vita-font;
	font-weight: $normal-weight;
    margin: 0;
    padding: 0;
}
.vita-contact p {
    font-size: $smaller-size;
	text-align: right;
	margin: 0 0 .2rem 0;
}
.vita-left {
	float: left;
	width: 60%;
}
.vita-right {
  float: right;
}
.vita-author {
    font-family: $vita-font;
    color: $text-color;
    font-size: $h1;
	font-weight: $normal-weight;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    padding: 0;
    border-bottom: none;
}
.vita h1 {
	font-family: $heading-font;
    font-size: 2.2rem;
	font-weight: $medium-weight;
    color: $theme-color;
    margin: 4rem 0 1rem 0;
    padding: 0 0 3px 0;
    border-bottom: 1px solid $text-color;
}
.vita h2 {
    font-family: $vita-font;
    font-size: 1.5rem;
	font-weight: $normal-weight;
    margin: 1.5rem 0 0.5rem 0;
}
.vita h3 {
    font-family: $vita-font;
    font-size: 1.25rem;
	font-weight: $normal-weight;
	font-style: italic;
	margin: .5rem 0 0 0;
}
.vita h4 {
    font-family: $vita-font;
	font-weight: $normal-weight;
	font-size: 1.25rem;
    font-style: italic;
	margin: 1.5rem 0 0 0;
}
.vita h5 {
    font-family: $vita-font;
    font-size: 1.1rem;
	font-weight: $normal-weight;
    font-style: italic;
    line-height: 1.5;
	margin-bottom: 0;
}

@media only screen
and (max-device-width:576px){
    .vita {
        .left {
            width: 55%;
            margin: 0;
            padding: 0;
        }
        .right {
            width: 45%;
            margin: 0;
            padding: 0;
        }
    }
    .vita {
        font-size: 1em;
        padding-bottom: 20px;
    }
    .vita ul {
        font-size: 1em;
    }
    .vita p {
        font-size: 1em;
    }
    .vita h1 {
        font-size: $h2;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .vita h2 {
        font-size: $h3;
        margin-top: 1rem;
        margin-bottom: 0.8em;
    }
    .vita h3 {
        font-size: $h4;
    }
    .vita h4 {
        font-size: $h4;
    }
    .vita h5 {
        font-size: $h4;
    }
    .vita h6 {
        font-size: $h5;
        line-height: 1.3;
        margin: 0 0 .5rem 0;
    }
    .vita h2 + h3,
    .vita h2 + h4,
    .vita h3 + h4 {
        margin-top: 0.5rem;
    }
    .vita-author h1 {
        font-size: $h1;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }
}

.vita-print-footer {
	position: absolute;
	left: 0px;
	bottom: 0px;
}
.vita-print-footer p {
	font-family: $sans-font;
	font-size: 8pt;
	font-weight: $light-weight;
}
.vita-print-footer-block {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
}

.alignleft {
    font-family: $mono-font;
    font-size: $smallest-size;
	width:50%;
	text-align:left;
}
.alignright {
    font-family: $mono-font;
    font-size: $smallest-size;
	width:50%;
	text-align:right;
}

// Hide print footer in screen display
@media screen {
	.vita-print-footer {
		display: none;
	}
}