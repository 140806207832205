/* Buttons (Bijou) */

@use "sass:color";

.alert{
	padding:15px;
	margin:10px 0;
	border-radius:3px;
	&.primary{
		background:color.adjust($primary, $lightness: 40%);
		border:1px color.adjust($primary, $lightness: 30%) solid;
	}
	&.success{
		background:color.adjust($success, $lightness: 40%);
		border:1px color.adjust($success, $lightness: 30%) solid;
	}
	&.danger{
		background:color.adjust($danger, $lightness: 40%);
		border:1px color.adjust($danger, $lightness: 30%) solid;
	}
}
.button {
    color: $off-white;
    font-family: $sans-font;
    font-size: $body-size;
    text-align: center;
    text-decoration: none;
    display: inline-block;
	border:0;
	border-radius:4px;
	outline:0;
    padding: .5rem .5rem;
    margin: 0;
    background: $theme-color;
    cursor: pointer;
	&:hover{
		background: color.adjust($theme-color, $lightness: -20%)
	}
	&.smaller{
		padding:7px 10px;
		font-size: $smallest-size;
		font-weight: $medium-weight;
	}
	&.small{
		padding:7px 20px;
		font-size: $smaller-size;
		font-weight: $medium-weight;
	}
	&.large{
		padding:15px 45px;
		font-size: $large-size;
	}
	&.large2{
		padding:15px 45px;
		font-size: $larger-size;
	}
	&.primary{
		color:#FFF;
		background:$theme-color;
		&:hover{
			background: color.adjust($theme-color, $lightness: -3%);
		}
	}
	&.syllabus{
		margin-top: 1rem;
		color: $off-white;
		background: $theme-color;
		&:hover{
			background: color.adjust($theme-color, $lightness: -20%);
		}
	}
	&.success{
		color:#FFF;
		background: $success;
		&:hover{
			background: color.adjust($success, $lightness: -3%);
		}
	}
	&.danger{
		color:#FFF;
		background: $danger;
		&:hover{
			background:color.adjust($danger, $lightness: -3%);
		}
	}
}