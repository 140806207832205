   
@media print {
	@page {
		margin-top: 2cm;
		margin-bottom: 3cm;
		margin-left: 0;
		margin-right: 0;
		margin-inside: 2cm;
		margin-outside: 2cm;
		padding: 0 0 0 0;
		size: letter portrait;
		background-color: transparent;
		orphans: 4;
		widows: 2;
		font-size: 10pt;
		@leftnote { width: 0;}
		@rightnote { width: 40mm; padding-left: 5mm; padding-top: 5mm;}
	}
	@page:left {
		@top-left { content: normal; }
		@top-center {  content: normal; }
		@top-right { content: normal; }
	}
	@page:right {
		@top-left { content: normal; }
		@top-center { content: normal; }
		@top-right { content: normal; }
	}
  	@page:first {
		@top-left { content: normal; }
		@top-center { content: normal; }
		@top-right { content: normal; }
	}
	html {
		font-size: 10pt;
	}
	body {
		width: 100%;
		margin: 0 0 0 0;
		padding: .5cm 0 0 0;
		background-color: transparent;
	}
	page-container {
		padding: 0 0 0 0;
	}
	section {
		padding: 0 0 0 0;
	}
	p {
		font-size: 10pt;
        text-align-all: justify;
        text-align-last: left;
	}
	h1 {
		font-size: 22pt;
		hyphens: none;
        text-align: left;
		break-after: avoid;
		string-set: chapter_title content();
	}
	.casebook h1:before {
		content: "Chapter " counter(chapter) ": ";
	}
	h2 {
		font-size: 20pt;
		font-weight: $normal-weight;
		hyphens: none;
        text-align: left;
		break-after: avoid;
	}
	h3 {
		font-size: 18pt;
		font-weight: $normal-weight;
		hyphens: none;
        text-align: left;
		break-after: avoid;
	}
	h4 {
		font-size: 16pt;
		font-weight: $normal-weight;
		hyphens: none;
        text-align: left;
		break-after: avoid;
	}
	h5 {
		font-size: 14pt;
		hyphens: none;
        text-align: left;
		break-after: avoid;
	}
	h6 {
		font-size: 12pt;
		margin-top: 1.5rem;
		hyphens: none;
        text-align: left;
		break-after: avoid;
	}
	div.book-cover {
		display: block;
		page: cover;
		break-after: left;
	}
	@page cover {
		@top-left { content: normal; }
		@top-center { content: normal; }
		@top-right { content: normal; }
    }
	div.title-block {
		margin: 4cm 0 0 0;
	}
	p.book-title {
		font-size: 36pt;
		string-set: book_title content();
	}
	p.book-subtitle {
		font-size: 18pt;
	}
	p.book-author {
		font-size: 14pt;
		string-set: book_author content();
	}
	div.publication-block{
		-prince-float: bottom;
	}
	p.book-affiliation {
		font-size: 14pt;
	}
	p.book-date {
		font-size: 14pt;
	}
	div.colophon {
		display: block;
		page: colophon;
		line-height: $shortest-line-height;
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		break-after: right;
        -prince-float: bottom;
	}
	@page colophon {
		@top-left { content: normal; }
		@top-center { content: normal; }
		@top-right { content: normal; }
    }
	p.colophon {
		font-size: 9pt;
		line-height: $shortest-line-height;
		margin: 0 0 0 0;
		padding: 0 0 0 0;
	}
	div.epigraph {
		display: block;
		page: epigraph;
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		break-after: right;
	}
	@page epigraph {
        @bottom-center { content: normal; }
	}
    @page epigraph:blank {
        @bottom-center { content: counter(page, lower-roman); }
    }
	p.epigraph-quote {
		font-style: italic;
		margin: 25% 0 0 0;
	}
	p.epigraph-cite {
		text-align: right;
	}
	div.contents {
		display: block;
		page: contents;
		margin: 0 0 0 0;
		padding: 0 0 0 0;
		break-after: right;
	}
    @page contents {
        @bottom-center { content: counter(page, lower-roman); }
    }
    @page contents:blank {
        @bottom-center { content: counter(page, lower-roman); }
    }
	#toc ol, ul { 
		list-style-type: none; 
		font-size: $large-size;
		font-weight: $normal-weight;
		}
	#toc, #ix, li { 
		margin-top: 1rem; 
	}
	#toc a::after {
		content: leader('.') target-counter(attr(href), page);
	}
	#toc li.h1 { 
		font-size: $large-size;
		margin-left: 0;
		break-after: avoid;
	}
	#toc li.h2 { 
		font-size: $body-size;
		margin-left: 2em;
	}
	#toc li.h3 { 
		font-size: $body-size;
		font-style: italic;
		margin-left: 4em;
	}
	div.preface {
		display: block;
		page: preface;
		width: 100%;
		margin: 0 0 0 0;
		padding: 0 0 0 0;
        break-before: right;
		counter-reset: page 1;
	}
	@page preface {
		margin-top: 4cm;
        @bottom-center { content: normal; }
	}
    @page preface:left {
        @bottom-center { content: counter(page); }
    }
	@page preface:first {
        @bottom-center { content: normal; }
	}
    @page preface:blank {
        @bottom-center { content: counter(page); }
    }
	div.casebook {
		display: block;
		width: 100%;
		padding: 0 0 0 0;
		padding: 0 0 0 0; 
        break-before: right;
	}
	div.chapter {
		display: block;
		width: 100%;
		padding: 0 0 0 0;
		padding: 0 0 0 0; 
		page: chapter;
        break-before: right;
        -prince-page-group: start;
	}
	@page chapter {
		border-top: thin black solid;
		}
	@page chapter:left {
		@top-left {
			font-family: $sans-font;
			content: counter(page);
			vertical-align: bottom;
		}
		@top-center {
			font-family: $sans-font;
			content: string(book_title);
			vertical-align: bottom;
		}
		@top-right {
			content: normal;
		}
	}
	@page chapter:right {
		@top-left {
			content: normal;
		}
		@top-center {
			font-family: $sans-font;
			content: string(chapter_title);
			vertical-align: bottom;
		}
		@top-right {
			font-family: $sans-font;
			content: counter(page);
			vertical-align: bottom;
		}
	}
	@page chapter:first {
		border-top: none;
		margin-top: 4cm;
		@top-left {
			content: normal;
		}
		@top-center {
			content: normal;
		}
		@top-right {
			content: normal;
		}
	}
	p.chapter-number {
		margin-bottom: 0;
		break-before: right;
		break-after: avoid;
	}
	p.case-h1 {
		font-size: 14pt;
		hyphens: none;
        text-align: left;
	}
	p.case-h2 {
		font-size: 12pt;
		hyphens: none;
        text-align: left;
	}
	p.case-h3 {
		font-size: 11pt;
		hyphens: none;
        text-align: left;
	}
	p.case-h4 {
		font-size: 11pt;
		hyphens: none;
        text-align: left;
	}
	ol, ul {
		text-align: justify;
	}
	li {
		text-align: justify;
	}
	.comment {
		font-family: $sans-font;
		font-weight: $light-weight;
		margin: 5rem 0;
		padding: 1rem .5rem;
		background-color: $azure;
		border-top: none;
		border-bottom: none;
	}
	.casebook ul {
		list-style-type: disc;
	}
	.comment ul { 
		list-style-type: disc;
	}
	.casebook figure.fullwidth figcaption { 
		margin: .3cm 0 .6cm 0; 
        -prince-float: bottom;
        -prince-float-policy: in-order;
		}
	.sidenote, .marginnote {
		float: none;
		clear: none;
		margin: 0 0 4pt 0;
		width: 3.8cm;
		hyphens: auto;
        text-align: left;
		hyphenate-lines: 1;
		-prince-float: rightnote;
	}
	.sidenote { 
		font-size: 8pt;
		font-weight: $light-weight;
	}
	.marginnote {
		font-size: 8pt;
		font-weight: $light-weight;
	}
	blockquote .sidenote, blockquote .marginnote {
		margin: 0 0 4pt 0;
		width: 3.75cm;
		hyphens: auto;
		hyphenate-lines: 1;
		-prince-float: rightnote;
	}
	thead {
		display: table-header-group;
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	header {
		display: none;
	}
	.button {
		display: none;
	}
	img {
		max-width: 90% !important;
	}
	hr {
		margin-top: 4em;
		margin-bottom: 4em;
	}
	p {
	  orphans: 4;
	  widows: 2;
	}
	h2,
	h3,
	h4 {
	  orphans: 4;
	  widows: 4;
	}
	p.case-h1, p.case-h2, p.case-h3, p.case-h4 {
		orphans: 4;
		widows: 4;
		break-after: avoid;
	}
	.leftalign {
		hyphens: none;
        text-align: left;
	}
}
// Hide print header/footer in screen display
@media screen {
	.print-header {
		display: none;
	}
	.print-footer {
		display: none;
	}
	.print-headfoot-block {
		display: none;
	}
}
